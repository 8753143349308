<template>
  <!-- eslint-disable -->
  <div class="content">
    <div class="qt-block-ui" v-if="loadPage"></div>
    <!-- <PageHeader title="Omni Business" subtitle="Canais" /> -->
    <PageHeader title="Chat" subtitle="Canais" />
    <!-- Add Widget -->

    <b-sidebar
      class="right-menu right-menu-channel"
      ref="menuChannels"
      id="menuWidget"
      v-model="menus.Widget"
    >
      <aside class="menu-channels">
        <header class="card-header d-flex justify-content-between">
          <div>Widget</div>
          <span class="status bg-secondary channel-status bg-success">
            Ativo
          </span>
        </header>
        <div class="menu-channels-body icon-set">
          <p>
            Forneça suporte online instantâneo, capturar mais leads e construir
            melhores relacionamentos com os clientes. Mais do que um chat, um
            canal de suporte - Yuper Chat!
          </p>
          <hr />
          <div class="row" style="padding-left: 20px; padding-right: 20px">
            <div class="icon col-xs-6 col-md-6">
              <router-link to="/live/configs/widget" class="tile-primary">
                <i class="fas fa-code"></i>
                <span>Instalar</span>
              </router-link>
            </div>
            <div class="icon col-xs-6 col-md-6">
              <router-link to="/live/simulator" class="tile-primary">
                <i class="fas fa-external-link-alt"></i>
                <span>Simular</span>
              </router-link>
            </div>
          </div>
          <i class="fas fa-globe icon-b"></i>
        </div>
      </aside>
    </b-sidebar>
    <b-sidebar
      class="right-menu right-menu-channel"
      ref="menuChannels"
      id="menuWhatsApp"
      v-model="menus.WhatsApp"
    >
      <aside class="menu-channels">
        <header class="card-header d-flex justify-content-between">
          <div>WhatsApp</div>
          <span
            class="status bg-secondary channel-status"
            :class="{ 'bg-success': whatsClient.status == 'active' }"
          >
            {{ whatsClient.status == 'active' ? 'Ativo' : 'Inativo' }}
          </span>
        </header>
        <div class="menu-channels-body icon-set">
          <p>
            Enriqueça seu atendimento no aplicativo de mensagens mais popular do
            mundo. Ofereça suporte imediato e uma experiência personalizada aos
            seus clientes com o WhatsApp Business API.
          </p>
          <hr />
          <div class="row" style="padding-left: 20px; padding-right: 20px">
            <div
              class="icon col-xs-6 col-md-6"
              v-if="whatsClient.status == 'active'"
            >
              <router-link to="/whatsapp/send" class="tile-primary">
                <i class="fas fa-paper-plane"></i>
                <span>Enviar mensagem</span>
              </router-link>
            </div>
            <div
              class="icon col-xs-6 col-md-6"
              v-if="whatsClient.status == 'active'"
            >
              <router-link to="/whatsapp/templates" class="tile-primary">
                <i class="fas fa-archive"></i>
                <span>Templates</span>
              </router-link>
            </div>
            <div
              class="icon col-xs-6 col-md-6"
              v-if="whatsClient.status != 'active'"
            >
              <a
                href="https://hub.360dialog.com/lp/whatsapp/YZKzG7PA"
                class="tile-success hide"
              >
                <i class="fas fa-check"></i>
                <span>Ativar Canal</span>
              </a>
            </div>
            <div class="icon col-xs-12 col-md-12">
              <a @click="metMessage" class="tile-success c-pointer">
                <i class="fas fa-plus"></i>
                <span>Adicionar Número</span>
              </a>
            </div>
            <div
              v-if="this.whatsNumbers.length > 0"
              class="col-xs-12 col-md-12"
            >
              <h3>Números</h3>
              <table class="table v-align-middle">
                <thead class="bg-light">
                  <tr>
                    <th class="p-l-20">Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in whatsNumbers" :key="i">
                    <td>
                      <strong class="nowrap limit-page">{{
                        item.phone_number
                      }}</strong>
                    </td>
                    <td align="right">
                      <button
                        class="btn btn-danger btn-rounded btn-sm"
                        @click="removeNumber(item.phone_identification)"
                      >
                        <i class="fas fa-trash color-white"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="text-center no-pages"
              v-if="whatsNumbers.length == 0">
              <i class="fa fa-tag"></i>
              <h4>Nenhum número</h4>
            </div>
          </div>
          <i class="fab fa-whatsapp icon-b"></i>
        </div>
      </aside>
    </b-sidebar>
    <b-sidebar
      class="right-menu right-menu-channel"
      ref="menuChannels"
      id="menuMessenger"
      v-model="menus.Messenger"
    >
      <aside class="menu-channels">
        <header
          class="card-header d-flex justify-content-between"
          style="text-align: left"
        >
          <div>Facebook Messenger</div>
          <span
            class="status bg-secondary channel-status"
            :class="{
              'bg-success':
                this.channels.messenger.active || this.pages.length > 0,
            }"
          >
            {{
              this.channels.messenger.active || this.pages.length > 0
                ? 'Ativo'
                : 'Inativo'
            }}
          </span>
        </header>
        <div class="menu-channels-body icon-set">
          <p>
            Mantenha presença da marca, ofereça suporte eficaz ao cliente,
            aumente as conversões de vendas e expanda seus negócios com o
            Facebook Messenger.
          </p>
          <hr />
          <div class="row" style="padding-left: 20px; padding-right: 20px">
            <div
              class="icon col-xs-6 col-md-6"
              v-if="!this.channels.messenger.active && this.pages.length == 0"
            >
              <a :href="messengerRouter" class="tile-success">
                <i class="fas fa-check"></i>
                <span>Ativar Canal</span>
              </a>
            </div>
            <div
              class="icon col-xs-6 col-md-6"
              v-if="this.channels.messenger.active || this.pages.length > 0"
            >
              <a :href="messengerRouter" class="tile-success">
                <i class="fas fa-plus"></i>
                <span>Adicionar Página</span>
              </a>
            </div>
            <div
              v-if="this.channels.messenger.active || this.pages.length > 0"
              class="col-xs-12 col-md-12"
            >
              <h3>Páginas</h3>
              <table class="table v-align-middle" style="z-index: 2000">
                <thead class="bg-light">
                  <tr>
                    <th class="p-l-20">Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in pages" :key="i">
                    <td>
                      <strong class="nowrap limit-page">{{ item.name }}</strong>
                    </td>
                    <td align="right">
                      <button
                        class="btn btn-danger btn-rounded btn-sm"
                        @click="
                          removePage(
                            channels.messenger.channel_id,
                            item.identification,
                          )
                        "
                      >
                        <i class="fas fa-trash color-white"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <i class="fab fa-facebook-messenger icon-b"></i>
        </div>
      </aside>
    </b-sidebar>
    <b-sidebar
      class="right-menu right-menu-channel"
      ref="menuChannels"
      id="menuInstagram"
      v-model="menus.Instagram"
    >
      <aside class="menu-channels">
        <header
          class="card-header d-flex justify-content-between"
          style="text-align: left"
        >
          <div>Instagram</div>
          <span
            class="status bg-secondary channel-status"
            :class="{ 'bg-success': this.channels.instagram.active }"
          >
            {{
              this.channels.instagram.active || this.pagesInstagram.length > 0
                ? 'Ativo'
                : 'Inativo'
            }}
          </span>
        </header>
        <div class="menu-channels-body icon-set">
          <p>
            Melhore a satisfação do cliente e impulsione as vendas por meio de
            conversas significativas com seus clientes no Instagram.
          </p>
          <hr />
          <div class="row" style="padding-left: 20px; padding-right: 20px">
            <div
              class="icon col-xs-6 col-md-6"
              v-if="
                !this.channels.instagram.active ||
                this.pagesInstagram.length == 0
              "
            >
              <a :href="instagramRouter" class="tile-success">
                <i class="fas fa-check"></i>
                <span>Ativar Canal</span>
              </a>
            </div>
            <div
              class="icon col-xs-6 col-md-6"
              v-if="
                this.channels.instagram.active && this.pagesInstagram.length > 0
              "
            >
              <a :href="instagramRouter" class="tile-success">
                <i class="fas fa-plus"></i>
                <span>Adicionar Instagram</span>
              </a>
            </div>
            <div
              v-if="
                this.channels.instagram.active || this.pagesInstagram.length > 0
              "
              class="col-xs-12 col-md-12"
            >
              <h3>Intagrans</h3>
              <table class="table v-align-middle">
                <thead class="bg-light">
                  <tr>
                    <th class="p-l-20">Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in pagesInstagram" :key="i">
                    <td>
                      <strong class="nowrap limit-page">{{ item.name }}</strong>
                    </td>
                    <td align="right">
                      <button
                        class="btn btn-danger btn-rounded btn-sm"
                        @click="removeInstagramPage(item.identification)"
                      >
                        <i class="fas fa-trash color-white"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <i class="fab fa-instagram icon-b"></i>
        </div>
      </aside>
    </b-sidebar>
    <b-sidebar
      class="right-menu right-menu-channel"
      ref="menuChannels"
      id="menuRCS"
      v-model="menus.RCS"
    >
      <aside class="menu-channels">
        <header class="card-header d-flex justify-content-between">
          <div>RCS</div>
          <span
            class="status bg-secondary channel-status"
            :class="{ 'bg-success': rcsClient.status == 'active' }"
          >
            {{ rcsClient.status == 'active' ? 'Ativo' : 'Inativo' }}
          </span>
        </header>
        <div class="menu-channels-body icon-set">
          <p>
            Trata-se de um novo padrão de mensagens por celular, desenvolvido
            para substituir o antiquado SMS, que faz a cobrança através da
            franquia de dados e não por cada mensagem enviada.
          </p>
          <hr />
          <div class="row">
            <div
              class="icon col-xs-6 col-md-6"
              v-if="rcsClient.status == 'active'"
            >
              <router-link to="/rcs/send" class="tile-primary">
                <i class="fas fa-paper-plane"></i>
                <span>Enviar mensagem</span>
              </router-link>
            </div>
            <div
              class="icon col-xs-6 col-md-6"
              v-if="rcsClient.status != 'active'"
            >
              <a
                href="https://yupchat.typeform.com/to/b0aG4Maq"
                class="tile-success"
              >
                <i class="fas fa-check"></i>
                <span>Ativar Canal</span>
              </a>
            </div>
          </div>
          <i class="fab fa-google icon-b"></i>
        </div>
      </aside>
    </b-sidebar>
    <b-sidebar
      class="right-menu"
      ref="menuChannels"
      id="menuReclameAqui"
      v-model="menus.ReclameAqui"
    >
      <aside>
        <header>Reclame Aqui</header>
        Conteudo aqui
      </aside>
    </b-sidebar>
    <b-sidebar
      class="right-menu right-menu-channel"
      ref="menuChannels"
      id="menuTelegram"
      v-model="menus.Telegram"
    >
      <aside class="menu-channels">
        <header
          class="card-header d-flex justify-content-between"
          style="text-align: left"
        >
          <div>Telegram</div>
          <span
            class="status bg-secondary channel-status"
            :class="{ 'bg-success': this.channels.telegram.active }"
          >
            {{ this.channels.telegram.active ? 'Ativo' : 'Inativo' }}
          </span>
        </header>
        <div class="menu-channels-body icon-set">
          <p>
            Envolva seus clientes no canal de mensagens do Telegram e forneça
            soluções instantâneas.
          </p>
          <hr />
          <div class="row" style="padding-left: 20px; padding-right: 20px">
            <div
              class="icon col-xs-6 col-md-6"
              v-if="!this.channels.telegram.active && this.bots.length == 0"
            >
              <a @click="addTelegramBot" class="tile-success">
                <i class="fas fa-check"></i>
                <span>Ativar Canal</span>
              </a>
            </div>
            <div
              class="icon col-xs-6 col-md-6"
              v-if="this.channels.telegram.active || this.bots.length > 0"
            >
              <a @click="addTelegramBot" class="tile-success c-pointer">
                <i class="fas fa-plus"></i>
                <span>Adicionar bot</span>
              </a>
            </div>
            <div
              v-if="this.channels.telegram.active || this.bots.length > 0"
              class="col-xs-12 col-md-12"
            >
              <h3>Bots</h3>
              <table class="table v-align-middle" style="z-index: 2000">
                <thead class="bg-light">
                  <tr>
                    <th class="p-l-20">Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in bots" :key="i">
                    <td>
                      <strong class="nowrap limit-page">{{
                        item.firstName
                      }}</strong>
                    </td>
                    <td align="right">
                      <button
                        class="btn btn-danger btn-rounded btn-sm"
                        @click="removeBot(item.botId)"
                      >
                        <i class="fas fa-trash color-white"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <i class="fab fa-telegram icon-b"></i>
        </div>
      </aside>
    </b-sidebar>
    <b-sidebar
      class="right-menu right-menu-channel"
      ref="menuChannels"
      id="menuSMS"
      v-model="menus.SMS"
    >
      <aside class="menu-channels">
        <header class="card-header d-flex justify-content-between">
          <div>SMS</div>
          <span class="status bg-secondary channel-status bg-success">
            Ativo
          </span>
        </header>
        <div class="menu-channels-body icon-set">
          <p>
            Atraia e converta seus clientes por meio das mensagens de texto SMS.
            Configure sua campanha de SMS marketing em minutos e envie mensagens
            em massa para todos os seus clientes.
          </p>
          <hr />
          <div class="row" style="padding-left: 20px; padding-right: 20px">
            <div class="icon col-xs-6 col-md-6">
              <router-link to="/sms/send" class="tile-primary">
                <i class="fas fa-paper-plane"></i>
                <span>Enviar mensagem</span>
              </router-link>
            </div>
            <div class="icon col-xs-6 col-md-6">
              <router-link to="/sms/templates" class="tile-primary">
                <i class="fas fa-archive"></i>
                <span>Templates</span>
              </router-link>
            </div>
          </div>
          <i class="fas fa-sms icon-b"></i>
        </div>
      </aside>
    </b-sidebar>
    <b-sidebar
      class="right-menu right-menu-channel"
      ref="menuChannels"
      id="menuMail"
      v-model="menus.Mail"
    >
      <aside class="menu-channels">
        <header class="card-header d-flex justify-content-between">
          <div>Email</div>
          <span
            class="status bg-secondary channel-status"
            :class="{ 'bg-success': mailClient.active }"
          >
            {{ mailClient.active ? 'Ativo' : 'Inativo' }}
          </span>
        </header>
        <div class="menu-channels-body icon-set">
          <p>
            Comunique-se e fortaleça o relacionamento com seus clientes através
            do envio de e-mail. Crie jornadas personalizadas e campanhas de
            e-mail marketing.
          </p>
          <hr />
          <div class="row" style="padding-left: 20px; padding-right: 20px">
            <div class="icon col-xs-6 col-md-6" v-if="mailClient.active">
              <router-link to="/mail/send" class="tile-primary">
                <i class="fas fa-paper-plane"></i>
                <span>Enviar mensagem</span>
              </router-link>
            </div>
            <div class="icon col-xs-6 col-md-6" v-if="!mailClient.active">
              <router-link to="/mail/domains" class="tile-success">
                <i class="fas fa-check"></i>
                <span>Ativar Canal</span>
              </router-link>
            </div>
          </div>
          <i class="fas fa-envelope-open icon-b"></i>
        </div>
      </aside>
    </b-sidebar>
    <section class="page-content container-fluid">
      <div class="container-add">
        <div class="row">
          <div class="col-12">
            <div class="card card-add-list">
              <div class="card-body">
                <div class="col-8 d-flex justify-content-center flex-column">
                  <p class="card-text" style="margin-top: 50px">
                    Ative os canais que gostaria de usar em suas prospecções no
                    sistema Chat<!-- Omni Business -->.
                  </p>
                  <div class="w-100"></div>
                </div>
                <div
                  class="col d-flex justify-content-center align-items-center"
                >
                  <div class="img-wrapper hide">
                    <img
                      src="assets/img/icons/channel_anime.gif"
                      alt="Add"
                      width="200"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-12">
          <div class="card-flow-template">
            <div class="card-body relative" style="height: 250px !important">
              <div class="card-flow-header">
                <div class="channel-item-card-status">
                  <div class="channel-item-status status-active">
                    <div class="status-indicate"></div>
                    <div class="status-text">Ativo</div>
                  </div>
                </div>
                <div class="flow-channel-item-menu relative text-right">
                  <router-link to="/live/configs/widget">
                    <span
                      class="material-symbols-outlined title"
                      style="color: #697077"
                    >
                      settings
                    </span>
                  </router-link>
                </div>
              </div>
              <div>
                <img
                  src="assets/img/icons/flow/widget.svg"
                  class="c-pointer"
                  style="
                    width: 30px;
                    height: 40px;
                    float: left;
                    margin-top: 4px;
                    margin-right: 10px;
                  "
                />
                <div
                  class="element-flow-item-title"
                  style="margin-top: 10px; float: left; font-size: 20px"
                >
                  Widget
                </div>
              </div>
              <div
                class="element-flow-item-card"
                style="margin-bottom: 0px !important"
              >
                <p style="display: block; max-height: 100px; overflow: hidden">
                  {{ channels.widget.description }}
                </p>
              </div>
              <span class="refresh-date-item">
                <router-link
                  to="/live/simulator"
                  class="create_flow_btn"
                >
                  Simular
                </router-link>
                <router-link to="/live/configs/widget" class="create_flow_btn">
                  <span
                    class="material-symbols-outlined to-right"
                    style="font-size: 20px"
                    >settings</span
                  >
                </router-link>
              </span>
              <div style="clear: both"></div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12">
          <div class="card-flow-template">
            <div class="card-body relative" style="height: 250px !important">
              <div class="card-flow-header">
                <div class="channel-item-card-status">
                  <div
                    class="channel-item-status status-active"
                    v-if="this.channels.instagram.active"
                  >
                    <div class="status-indicate"></div>
                    <div class="status-text">Ativo</div>
                  </div>
                  <div
                    class="channel-item-status status-inactive"
                    v-if="!this.channels.instagram.active"
                  >
                    <div class="status-indicate"></div>
                    <div class="status-text">Inativo</div>
                  </div>
                </div>
                <div
                  class="flow-channel-item-menu relative text-right"
                  v-b-toggle.sidebar-right-channel
                >
                  <span
                    class="material-symbols-outlined title"
                    @click="currentChannel = channels.instagram"
                  >
                    settings
                  </span>
                </div>
              </div>
              <div style="margin-top: 10px">
                <img
                  src="assets/img/icons/flow/instagram.svg"
                  class="c-pointer"
                  style="
                    width: 30px;
                    height: 40px;
                    float: left;
                    margin-top: 4px;
                    margin-right: 15px;
                  "
                />
                <div
                  class="element-flow-item-title"
                  style="margin-top: 10px; float: left; font-size: 20px"
                >
                  Instagram
                </div>
              </div>
              <div
                class="element-flow-item-card"
                style="margin-bottom: 0px !important"
              >
                <p style="display: block; max-height: 100px; overflow: hidden">
                  {{ this.channels.instagram.description }}
                </p>
              </div>
              <span class="refresh-date-item">
                <a
                  class="create_flow_btn c-pointer"
                  v-b-toggle.sidebar-right-channel
                  @click="currentChannel = channels.instagram"
                  >Editar canal<span
                    class="material-symbols-outlined to-right"
                    style="font-size: 20px"
                    >settings</span
                  ></a
                >
              </span>
              <div style="clear: both"></div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12">
          <div class="card-flow-template">
            <div class="card-body relative" style="height: 250px !important">
              <div class="card-flow-header">
                <div class="channel-item-card-status">
                  <div
                    class="channel-item-status status-active"
                    v-if="this.channels.telegram.active"
                  >
                    <div class="status-indicate"></div>
                    <div class="status-text">Ativo</div>
                  </div>
                  <div
                    class="channel-item-status status-inactive"
                    v-if="!this.channels.telegram.active"
                  >
                    <div class="status-indicate"></div>
                    <div class="status-text">Inativo</div>
                  </div>
                </div>
                <div
                  class="flow-channel-item-menu relative text-right"
                  v-b-toggle.sidebar-right-channel
                >
                  <span
                    class="material-symbols-outlined title"
                    @click="currentChannel = channels.telegram"
                  >
                    settings
                  </span>
                </div>
              </div>
              <div style="margin-top: 10px">
                <img
                  src="assets/img/icons/flow/telegram.svg"
                  class="c-pointer"
                  style="
                    width: 30px;
                    height: 40px;
                    float: left;
                    margin-top: 4px;
                    margin-right: 15px;
                  "
                />
                <div
                  class="element-flow-item-title"
                  style="margin-top: 10px; float: left; font-size: 20px"
                >
                  Telegram
                </div>
              </div>
              <div
                class="element-flow-item-card"
                style="margin-bottom: 0px !important"
              >
                <p style="display: block; max-height: 100px; overflow: hidden">
                  {{ this.channels.telegram.description }}
                </p>
              </div>
              <span class="refresh-date-item">
                <a
                  class="create_flow_btn c-pointer"
                  v-if="this.channels.telegram.active"
                  v-b-toggle.sidebar-right-channel
                  @click="currentChannel = channels.telegram"
                  >Editar canal<span
                    class="material-symbols-outlined to-right"
                    style="font-size: 20px"
                    >settings</span
                  ></a
                >
                <a
                  class="create_flow_btn c-pointer"
                  v-if="!this.channels.telegram.active"
                  @click="currentChannel = channels.telegram"
                  v-b-toggle.sidebar-right-channel
                  >Ativar canal<span
                    class="material-symbols-outlined to-right"
                    style="font-size: 20px"
                    >keyboard_double_arrow_right</span
                  ></a
                >
              </span>
              <div style="clear: both"></div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12">
          <div class="card-flow-template">
            <div class="card-body relative" style="height: 250px !important">
              <div class="card-flow-header">
                <div class="channel-item-card-status">
                  <div
                    class="channel-item-status status-active"
                    v-if="this.channels.messenger.active"
                  >
                    <div class="status-indicate"></div>
                    <div class="status-text">Ativo</div>
                  </div>
                  <div
                    class="channel-item-status status-inactive"
                    v-if="!this.channels.messenger.active"
                  >
                    <div class="status-indicate"></div>
                    <div class="status-text">Inativo</div>
                  </div>
                </div>
                <div
                  class="flow-channel-item-menu relative text-right"
                  v-b-toggle.sidebar-right-channel
                >
                  <span
                    class="material-symbols-outlined title"
                    @click="currentChannel = channels.messenger"
                  >
                    settings
                  </span>
                </div>
              </div>
              <div style="margin-top: 10px">
                <img
                  src="assets/img/icons/flow/Messenger.svg"
                  class="c-pointer"
                  style="
                    width: 30px;
                    height: 40px;
                    float: left;
                    margin-top: 4px;
                    margin-right: 15px;
                  "
                />
                <div
                  class="element-flow-item-title"
                  style="margin-top: 10px; float: left; font-size: 20px"
                >
                  Messenger
                </div>
              </div>
              <div
                class="element-flow-item-card"
                style="margin-bottom: 0px !important"
              >
                <p style="display: block; max-height: 100px; overflow: hidden">
                  {{ this.channels.messenger.description }}
                </p>
              </div>
              <span class="refresh-date-item">
                <a
                  class="create_flow_btn c-pointer"
                  v-b-toggle.sidebar-right-channel
                  @click="currentChannel = channels.messenger"
                  >Editar canal<span
                    class="material-symbols-outlined to-right"
                    style="font-size: 20px"
                    >settings</span
                  ></a
                >
              </span>
              <div style="clear: both"></div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12">
          <div class="card-flow-template">
            <div class="card-body relative" style="height: 250px !important">
              <div class="card-flow-header">
                <div class="channel-item-card-status">
                  <div
                    class="channel-item-status status-active"
                    v-if="this.channels.google.active"
                  >
                    <div class="status-indicate"></div>
                    <div class="status-text">Ativo</div>
                  </div>
                  <div
                    class="channel-item-status status-inactive"
                    v-if="!this.channels.google.active"
                  >
                    <div class="status-indicate"></div>
                    <div class="status-text">Inativo</div>
                  </div>
                </div>
                <div
                  class="flow-channel-item-menu relative text-right"
                  v-b-toggle.sidebar-right-rcs
                >
                  <span
                    class="material-symbols-outlined title"
                    @click="currentChannel = channels.google"
                  >
                    settings
                  </span>
                </div>
              </div>
              <div style="margin-top: 10px">
                <img
                  src="assets/img/icons/flow/rcs.svg"
                  class="c-pointer"
                  style="
                    width: 30px;
                    height: 40px;
                    float: left;
                    margin-top: 4px;
                    margin-right: 15px;
                  "
                />
                <div
                  class="element-flow-item-title"
                  style="margin-top: 10px; float: left; font-size: 20px"
                >
                  RCS
                </div>
              </div>
              <div
                class="element-flow-item-card"
                style="margin-bottom: 0px !important"
              >
                <p style="display: block; max-height: 100px; overflow: hidden">
                  {{ this.channels.google.description }}
                </p>
              </div>
              <span class="refresh-date-item">
                <a
                  class="create_flow_btn c-pointer"
                  v-b-toggle.sidebar-right-rcs
                  v-if="this.channels.google.active"
                  @click="currentChannel = channels.google"
                  >Editar canal<span
                    class="material-symbols-outlined to-right"
                    style="font-size: 20px"
                    >settings</span>
                  </a
                >
                <a
                  class="create_flow_btn c-pointer"
                  v-if="!this.channels.google.active"
                  v-b-toggle.sidebar-right-rcs
                  >Ativar canal<span
                    class="material-symbols-outlined to-right"
                    style="font-size: 20px"
                    >keyboard_double_arrow_right</span
                  ></a
                >
              </span>
              <div style="clear: both"></div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12">
          <div class="card-flow-template">
            <div class="card-body relative" style="height: 250px !important">
              <div class="card-flow-header">
                <div class="channel-item-card-status">
                  <div
                    class="channel-item-status status-active"
                    v-if="this.channels.email.active"
                  >
                    <div class="status-indicate"></div>
                    <div class="status-text">Ativo</div>
                  </div>
                  <div
                    class="channel-item-status status-inactive"
                    v-if="!this.channels.email.active"
                  >
                    <div class="status-indicate"></div>
                    <div class="status-text">Inativo</div>
                  </div>
                </div>
                <div
                  class="flow-channel-item-menu relative text-right"
                  v-b-toggle.sidebar-right-channel
                >
                  <span
                    class="material-symbols-outlined title"
                    @click="currentChannel = channels.email"
                  >
                    settings
                  </span>
                </div>
              </div>
              <div style="margin-top: 10px">
                <img
                  src="assets/img/icons/flow/mail.svg"
                  class="c-pointer"
                  style="
                    width: 30px;
                    height: 40px;
                    float: left;
                    margin-top: 4px;
                    margin-right: 15px;
                  "
                />
                <div
                  class="element-flow-item-title"
                  style="margin-top: 10px; float: left; font-size: 20px"
                >
                  Email
                </div>
              </div>
              <div
                class="element-flow-item-card"
                style="margin-bottom: 0px !important"
              >
                <p style="display: block; max-height: 100px; overflow: hidden">
                  {{ this.channels.email.description }}
                </p>
              </div>
              <span class="refresh-date-item">
                <a
                  class="create_flow_btn c-pointer"
                  v-b-toggle.sidebar-right-channel
                  @click="currentChannel = channels.email"
                  >Editar canal<span
                    class="material-symbols-outlined to-right"
                    style="font-size: 20px"
                    >settings</span
                  ></a
                >
              </span>
              <div style="clear: both"></div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12">
          <div class="card-flow-template">
            <div class="card-body relative" style="height: 250px !important">
              <div class="card-flow-header">
                <div class="channel-item-card-status">
                  <div
                    class="channel-item-status status-active"
                    v-if="this.channels.sms.active"
                  >
                    <div class="status-indicate"></div>
                    <div class="status-text">Ativo</div>
                  </div>
                  <div
                    class="channel-item-status status-inactive"
                    v-if="!this.channels.sms.active"
                  >
                    <div class="status-indicate"></div>
                    <div class="status-text">Inativo</div>
                  </div>
                </div>
                <div
                  class="flow-channel-item-menu relative text-right"
                  v-b-toggle.sidebar-right-channel
                >
                  <span
                    class="material-symbols-outlined title"
                    @click="currentChannel = channels.sms"
                  >
                    settings
                  </span>
                </div>
              </div>
              <div style="margin-top: 10px">
                <img
                  src="assets/img/icons/flow/sms.svg"
                  class="c-pointer"
                  style="
                    width: 30px;
                    height: 40px;
                    float: left;
                    margin-top: 4px;
                    margin-right: 15px;
                  "
                />
                <div
                  class="element-flow-item-title"
                  style="margin-top: 10px; float: left; font-size: 20px"
                >
                  SMS
                </div>
              </div>
              <div
                class="element-flow-item-card"
                style="margin-bottom: 0px !important"
              >
                <p style="display: block; max-height: 100px; overflow: hidden">
                  {{ this.channels.sms.description }}
                </p>
              </div>
              <span class="refresh-date-item">
                <a
                  class="create_flow_btn c-pointer"
                  v-b-toggle.sidebar-right-channel
                  @click="currentChannel = channels.sms"
                  >Editar canal<span
                    class="material-symbols-outlined to-right"
                    style="font-size: 20px"
                    >settings</span
                  ></a
                >
              </span>
              <div style="clear: both"></div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12">
          <div class="card-flow-template">
            <div class="card-body relative" style="height: 250px !important">
              <div class="card-flow-header">
                <div class="channel-item-card-status">
                  <div
                    class="channel-item-status status-active"
                    v-if="this.channels.whatsapp.active"
                  >
                    <div class="status-indicate"></div>
                    <div class="status-text">Ativo</div>
                  </div>
                  <div
                    class="channel-item-status status-inactive"
                    v-if="!this.channels.whatsapp.active"
                  >
                    <div class="status-indicate"></div>
                    <div class="status-text">Inativo</div>
                  </div>
                </div>
                <div
                  class="flow-channel-item-menu relative text-right"
                  v-b-toggle.sidebar-right-channel
                >
                  <span
                    class="material-symbols-outlined title"
                    @click="currentChannel = channels.whatsapp"
                  >
                    settings
                  </span>
                </div>
              </div>
              <div style="margin-top: 10px">
                <img
                  src="assets/img/icons/flow/whatsapp.svg"
                  class="c-pointer"
                  style="
                    width: 30px;
                    height: 40px;
                    float: left;
                    margin-top: 4px;
                    margin-right: 15px;
                  "
                />
                <div
                  class="element-flow-item-title"
                  style="margin-top: 10px; float: left; font-size: 20px"
                >
                  Whatsapp
                </div>
              </div>
              <div
                class="element-flow-item-card"
                style="margin-bottom: 0px !important"
              >
                <p style="display: block; max-height: 100px; overflow: hidden">
                  {{ this.channels.whatsapp.description }}
                </p>
              </div>
              <span class="refresh-date-item">
                <a
                  class="create_flow_btn c-pointer"
                  v-b-toggle.sidebar-right-channel
                  @click="currentChannel = channels.whatsapp"
                  >Editar canal<span
                    class="material-symbols-outlined to-right"
                    style="font-size: 20px"
                    >settings</span
                  ></a
                >
              </span>
              <div style="clear: both"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <active-telegram-modal
      id="active-telegram-modal"
      @send="checkMessenger"
    ></active-telegram-modal>
    <active-whats-modal
      id="active-whats-modal"
      @send="checkMessenger"
    ></active-whats-modal>
    <b-sidebar
      class="relative"
      id="sidebar-right-channel"
      :title="$t('catalog.list.edit')"
      no-header
      right
      backdrop
      shadow
    >
      <div class="col sidebar-header">
        <p>Cadastrar</p>
        <span
          v-b-toggle.sidebar-right-channel
          class="material-symbols-outlined sidebar-close"
          >close</span
        >
      </div>
      <div class="col desc-column">
        <p>
          {{ currentChannel.description }}
        </p>
      </div>
      <div>
        <a
          class="btn-card-open-borderd c-pointer btn-add-new-account"
          :href="instagramRouter"
          v-if="currentChannel.iconHeader == 'instagram'"
          style="display: flex"
          >Cadastrar nova conta
          <span
            class="material-symbols-outlined to-right"
            style="font-size: 20px"
            >call_made</span
          >
          <div style="clear: both"></div>
        </a>
        <a
          class="btn-card-open-borderd c-pointer btn-add-new-account"
          :href="messengerRouter"
          v-if="currentChannel.iconHeader == 'facebook'"
          style="display: flex"
          >Cadastrar nova conta
          <span
            class="material-symbols-outlined to-right"
            style="font-size: 20px"
            >call_made</span
          >
          <div style="clear: both"></div>
        </a>
        <a
          class="btn-card-open-borderd c-pointer btn-add-new-account"
          v-if="currentChannel.iconHeader == 'whatsapp' && !activingWhats"
          style="display: flex"
          @click="activingWhats = true"
          >Cadastrar novo número
          <span
            class="material-symbols-outlined to-right"
            style="font-size: 20px"
            >call_made</span
          >
          <div style="clear: both"></div>
        </a>
        <a
          class="btn-card-open-borderd c-pointer btn-add-new-account"
          @click="metMessage"
          v-if="currentChannel.iconHeader == 'rcs'"
          style="display: flex"
          >Cadastrar novo agente
          <span
            class="material-symbols-outlined to-right"
            style="font-size: 20px"
            >call_made</span
          >
          <div style="clear: both"></div>
        </a>
        <router-link
          to="/mail/send"
          class="btn-card-open-borderd c-pointer btn-add-new-account"
          v-if="currentChannel.iconHeader == 'mail'"
          style="display: flex"
        >
          Enviar mensagem
          <span
            class="material-symbols-outlined to-right"
            style="font-size: 20px"
            >call_made</span
          >
          <div style="clear: both"></div>
        </router-link>
        <router-link
          to="/sms/send"
          class="btn-card-open-borderd c-pointer btn-add-new-account"
          v-if="currentChannel.iconHeader == 'sms'"
          style="display: flex"
        >
          Enviar mensagem
          <span
            class="material-symbols-outlined to-right"
            style="font-size: 20px"
            >call_made</span
          >
          <div style="clear: both"></div>
        </router-link>
        <router-link
          to="/sms/template"
          class="btn-card-open-borderd c-pointer btn-add-new-account"
          v-if="currentChannel.iconHeader == 'sms'"
          style="display: flex; padding-left: 20%"
        >
          Template
          <span
            class="material-symbols-outlined to-right"
            style="font-size: 20px"
            >palette</span
          >
          <div style="clear: both"></div>
        </router-link>
        <router-link
          to="/rcs/send"
          class="btn-card-open-borderd c-pointer btn-add-new-account"
          v-if="currentChannel.iconHeader == 'rcs'"
          style="display: flex"
        >
          Enviar mensagem
          <span
            class="material-symbols-outlined to-right"
            style="font-size: 20px"
            >call_made</span
          >
          <div style="clear: both"></div>
        </router-link>
      </div>
      <form class="add-prod-form col mb-0" @submit.prevent="saveWhatsApp" v-if="currentChannel.iconHeader == 'whatsapp' && activingWhats">
        <div class="input-group m-bottom-10">
          <label for=""
            >Nome</label
          >
          <input
            placeholder=""
            type="text"
            class="form-control border-radius-5"
            v-model="formWhatsApp.name"
            required
          />
        </div>
        <div class="input-group m-bottom-10">
          <label for=""
            >Email</label
          >
          <input
            placeholder=""
            type="text"
            class="form-control border-radius-5"
            v-model="formWhatsApp.email"
            required
          />
        </div>
        <div class="input-group m-bottom-10">
          <label for=""
            >Telefone</label
          >
          <input
            placeholder=""
            type="text"
            class="form-control border-radius-5"
            v-model="formWhatsApp.phone_number"
            required
          />
        </div>
        <div class="input-group m-bottom-10">
          <label for=""
            >Usuário</label
          >
          <input
            placeholder=""
            type="text"
            class="form-control border-radius-5"
            v-model="formWhatsApp.user_name"
            required
          />
        </div>
        <div class="modal-footer">
          <button
            :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
            :disabled="isSending"
            type="submit"
            class="btn btn-yup-purple"
          >
            {{$t('generic-str.save')}}
          </button>
          <button type="button" class="btn btn-yup-purple-outline" @click="activingWhats = false">
            {{$t('generic-str.cancel')}}
          </button>
        </div>
      </form>
      <form class="add-prod-form col mb-0">
        <div class="input-group" v-if="currentChannel.iconHeader == 'telegram'">
          <label for=""
            >Token <span class="material-symbols-outlined"> info </span></label
          >
          <input
            placeholder=""
            type="text"
            class="form-control border-radius-5"
            v-model="form.token"
          />
        </div>
        <div
          class="input-group"
          v-if="
            currentChannel.iconHeader != 'sms' &&
            currentChannel.iconHeader != 'telegram' &&
            currentChannel.iconHeader != 'mail' &&
            currentChannel.iconHeader != 'rcs' && !activingWhats
          "
        >
          <div class="info-header-sidebar">
            <h4>Contas cadastradas</h4>
          </div>
        </div>
        <div v-if="!activingWhats">
          <div
            class="account-list-side-bar"
            v-for="(item, i) in currentChannel.pages"
            :key="i"
          >
            <div class="account-list-side-bar-text">
              {{ item.phone_number ? item.phone_number : item.name }}
            </div>
            <div class="account-list-side-bar-icon">
              <span
                class="material-symbols-outlined c-pointer"
                @click="removeItem(currentChannel, item)"
                >delete</span
              >
            </div>
          </div>
        </div>
        <div
          class="text-center no-pages"
          v-if="whatsNumbers.length == 0 && currentChannel.iconHeader == 'whatsapp'">
          <i class="fa fa-tag"></i>
          <h4>Nenhum número</h4>
        </div>
        <div
          class="text-center no-pages"
          v-if="currentChannel.pages && currentChannel.pages.length == 0"
        >
          <i class="fa fa-tag"></i>
          <h4>Nenhuma página</h4>
        </div>
        <div v-if="!currentChannel.active && currentChannel.info">
          <ChannelFeatures :message="currentChannel.info.message" :features="currentChannel.info.features" />
        </div>
        <div v-if="!currentChannel.active && currentChannel.info">
          <ChannelFeatures :message="currentChannel.info.message" :features="currentChannel.info.features" />
        </div>
      </form>
      <div
        class="input-group text-right flex-c footer-sidebar-channel"
        v-if="currentChannel.iconHeader == 'telegram'"
      >
        <button
          type="button"
          v-b-toggle.sidebar-right-channel
          @click="anableTelegram"
          class="btn btn-yup btn-add"
        >
          {{ $t('flows.save') }}
        </button>
      </div>
    </b-sidebar>
    <b-sidebar
      class="relative"
      id="sidebar-right-rcs"
      :title="$t('catalog.list.edit')"
      no-header
      right
      backdrop
      shadow
    >
      <div class="col sidebar-header">
        <p>Ativar agente RCS</p>
        <span
          v-b-toggle.sidebar-right-rcs
          class="material-symbols-outlined sidebar-close"
          >close</span
        >
      </div>
      <div class="col desc-column" v-if="!callRcsSend">
        <p>
          Melhore a experiência do cliente e o marketing móvel. Envie
          experiências ricas, interativas e personalizadas diretamente no
          aplicativo de mensagens padrão em dispositivos Android.
        </p>
        <a
          class="btn-card-open-borderd c-pointer btn-add-new-account"
          style="display: flex"
          @click="callRcsSend = true"
          >Cadastrar novo agente</a>
      </div>
      <div v-if="!channels.google.active && !callRcsSend">
        <ChannelFeatures :message="channels.googleBm.info.message" :features="channels.googleBm.info.features" />
      </div>
      <div class="col desc-column" v-if="callRcsSend">
        <form @submit.prevent="saveRcs">
          <div class="modal-body">
            <div class="form-body">
              <div class="form-group row">
                <div class="col-sm-12">
                  <label class="control-label text-left text-left-m">Nome</label>
                  <input v-model="formRcs.name" type="text" class="form-control"/>
                </div>
                <div class="col-md-12">
                  <label class="control-label text-left text-left-m">Email de contato</label>
                  <input v-model="formRcs.business_mail" type="text" class="form-control"/>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12">
                  <label class="control-label text-left text-left-m">Nome da empresa</label>
                  <input v-model="formRcs.business_name" type="text" class="form-control"/>
                </div>
                <div class="col-md-12">
                  <label class="control-label text-left text-left-m">Telefone</label>
                  <input v-model="formRcs.phone_number" type="text" class="form-control"/>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12">
                  <label class="control-label text-left text-left-m">Link do site</label>
                  <input v-model="formRcs.site_link" type="text" class="form-control"/>
                </div>
                <div class="col-md-12">
                  <label class="control-label text-left text-left-m">URL da politica de privacidade do site</label>
                  <input v-model="formRcs.privacy_link" type="text" class="form-control"/>
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m">URL dos termos de uso do site</label>
                <div class="col-md-12">
                  <input v-model="formRcs.term_link" type="text" class="form-control"/>
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m">Opt-in</label>
                <div class="col-md-12">
                  <textarea v-model="formRcs.opt_in" type="text" class="form-control"></textarea>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12 col-lg-12 text-center">
                  <label class="control-label text-right text-center"
                    >Imagem grande do banner (1440 x 448)</label>
                  <div class="image-cover" id="cover-banner"></div>
                  <a @click="callUpload('cover-banner')" class="c-pointer btn btn-primary btn-upload"><span class="material-symbols-outlined">upload</span> Fazer upload</a>
                </div>
                <div class="col-md-12 col-lg-12 text-center">
                  <label class="control-label text-right"
                    >Logo (224 x 224)</label>
                  <div class="image-cover" id="cover-logo"></div>
                  <a @click="callUpload('cover-logo')" class="c-pointer btn btn-primary btn-upload"><span class="material-symbols-outlined">upload</span> Fazer upload</a>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              :disabled="isSending"
              type="submit"
              class="btn btn-yup-purple"
            >
              {{$t('generic-str.save')}}
            </button>
            <button type="button" class="btn btn-yup-purple-outline" @click="callRcsSend = false">
              {{$t('generic-str.cancel')}}
            </button>
          </div>
        </form>
      </div>
    </b-sidebar>
    <b-sidebar
      class="relative"
      id="sidebar-right-google"
      :title="$t('catalog.list.edit')"
      no-header
      right
      backdrop
      shadow
    >
      <div class="col sidebar-header">
        <p>Ativar agente Google Messenger</p>
        <span
          v-b-toggle.sidebar-right-google
          class="material-symbols-outlined sidebar-close"
          >close</span
        >
      </div>
      <div class="col desc-column" v-if="!callGoogleSend">
        <p>
          Conecte-se usando o Google Business Messages
        </p>
        <a
          class="btn-card-open-borderd c-pointer btn-add-new-account"
          style="display: flex"
          @click="callGoogleSend = true"
        >Cadastrar novo agente</a>
      </div>
      <div v-if="!channels.googleBm.active && !callGoogleSend">
        <ChannelFeatures :message="channels.googleBm.info.message" :features="channels.googleBm.info.features" />
      </div>
      <form @submit.prevent="saveGoogle" v-if="callGoogleSend">
        <div class="modal-body">
          <div class="form-body">
            <div class="form-group row">
              <div class="col-sm-12">
                <label class="control-label text-left text-left-m">Nome</label>
                <input v-model="formGoogle.name" type="text" class="form-control"/>
              </div>
              <div class="col-md-12">
                <label class="control-label text-left text-left-m">Email de contato</label>
                <input v-model="formGoogle.business_mail" type="text" class="form-control"/>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <label class="control-label text-left text-left-m">Nome da empresa</label>
                <input v-model="formGoogle.business_name" type="text" class="form-control"/>
              </div>
              <div class="col-md-12">
                <label class="control-label text-left text-left-m">Telefone</label>
                <input v-model="formGoogle.phone_number" type="text" class="form-control"/>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <label class="control-label text-left text-left-m">Link do site</label>
                <input v-model="formGoogle.site_link" type="text" class="form-control"/>
              </div>
              <div class="col-md-12">
                <label class="control-label text-left text-left-m">URL da politica de privacidade do site</label>
                <input v-model="formGoogle.privacy_link" type="text" class="form-control"/>
              </div>
            </div>
            <div class="form-group row">
              <label class="control-label text-left col-md-12 text-left-m">URL dos termos de uso do site</label>
              <div class="col-md-12">
                <input v-model="formGoogle.term_link" type="text" class="form-control"/>
              </div>
            </div>
            <div class="form-group row">
              <label class="control-label text-left col-md-12 text-left-m">Opt-in</label>
              <div class="col-md-12">
                <textarea v-model="formGoogle.opt_in" type="text" class="form-control"></textarea>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12 col-lg-12 text-center">
                <label class="control-label text-right text-center"
                  >Imagem grande do banner (1440 x 448)</label>
                <div class="image-cover" id="cover-banner-google"></div>
                <a @click="callUpload()" class="c-pointer btn btn-primary btn-upload"><span class="material-symbols-outlined">upload</span> Fazer upload</a>
              </div>
              <div class="col-md-12 col-lg-12 text-center">
                <label class="control-label text-right"
                  >Logo (224 x 224)</label>
                <div class="image-cover" id="cover-logo-google"></div>
                <a @click="callUpload()" class="c-pointer btn btn-primary btn-upload"><span class="material-symbols-outlined">upload</span> Fazer upload</a>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
            :disabled="isSending"
            type="submit"
            class="btn btn-yup-purple"
          >
            {{$t('generic-str.save')}}
          </button>
          <button type="button" class="btn btn-yup-purple-outline" @click="formGoogle = false">
            {{$t('generic-str.cancel')}}
          </button>
        </div>
      </form>
      <div v-if="!channels.googleBm.active && !callGoogleSend">
        <ChannelFeatures :message="channels.googleBm.info.message" :features="channels.googleBm.info.features" />
      </div>
      <form @submit.prevent="saveGoogle" v-if="callGoogleSend">
        <div class="modal-body">
          <div class="form-body">
            <div class="form-group row">
              <div class="col-sm-12">
                <label class="control-label text-left text-left-m">Nome</label>
                <input v-model="formGoogle.name" type="text" class="form-control"/>
              </div>
              <div class="col-md-12">
                <label class="control-label text-left text-left-m">Email de contato</label>
                <input v-model="formGoogle.business_mail" type="text" class="form-control"/>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <label class="control-label text-left text-left-m">Nome da empresa</label>
                <input v-model="formGoogle.business_name" type="text" class="form-control"/>
              </div>
              <div class="col-md-12">
                <label class="control-label text-left text-left-m">Telefone</label>
                <input v-model="formGoogle.phone_number" type="text" class="form-control"/>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <label class="control-label text-left text-left-m">Link do site</label>
                <input v-model="formGoogle.site_link" type="text" class="form-control"/>
              </div>
              <div class="col-md-12">
                <label class="control-label text-left text-left-m">URL da politica de privacidade do site</label>
                <input v-model="formGoogle.privacy_link" type="text" class="form-control"/>
              </div>
            </div>
            <div class="form-group row">
              <label class="control-label text-left col-md-12 text-left-m">URL dos termos de uso do site</label>
              <div class="col-md-12">
                <input v-model="formGoogle.term_link" type="text" class="form-control"/>
              </div>
            </div>
            <div class="form-group row">
              <label class="control-label text-left col-md-12 text-left-m">Opt-in</label>
              <div class="col-md-12">
                <textarea v-model="formGoogle.opt_in" type="text" class="form-control"></textarea>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12 col-lg-12 text-center">
                <label class="control-label text-right text-center"
                  >Imagem grande do banner (1440 x 448)</label>
                <div class="image-cover" id="cover-banner-google"></div>
                <a @click="callUpload()" class="c-pointer btn btn-primary btn-upload"><span class="material-symbols-outlined">upload</span> Fazer upload</a>
              </div>
              <div class="col-md-12 col-lg-12 text-center">
                <label class="control-label text-right"
                  >Logo (224 x 224)</label>
                <div class="image-cover" id="cover-logo-google"></div>
                <a @click="callUpload()" class="c-pointer btn btn-primary btn-upload"><span class="material-symbols-outlined">upload</span> Fazer upload</a>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
            :disabled="isSending"
            type="submit"
            class="btn btn-yup-purple"
          >
            {{$t('generic-str.save')}}
          </button>
          <button type="button" class="btn btn-yup-purple-outline" @click="formGoogle = false">
            {{$t('generic-str.cancel')}}
          </button>
        </div>
      </form>
    </b-sidebar>
    <request-modal id="request-modal"></request-modal>
    <request-modal-google id="request-modal-google"></request-modal-google>
    <m-crop id="m-crop" />
    <UploadComponent
      :preview="true"
      ref="uploadMenu"
      :accept="'image/*'"
      @uploaded="uploaded"
    />
  </div>
</template>

<script>
// @ is an alias to /src
// import Swal from 'sweetalert2';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import PageHeader from '@/components/rebranding/PageHeader.vue';
import RCSService from '@/services/rcs.service';
import WhatsAppService from '@/services/whatsapp.service';
import ChannelService from '@/services/channel.service';
import Swal from 'sweetalert2';
import MailService from '@/services/mail.service';
import axios from '@/plugins/axios';
import ActiveTelegramModal from '@/components/ActiveTelegramModal.vue';
import ActiveWhatsModal from '@/components/ActiveWhatsModal.vue';
import RequestModal from '@/components/rebranding/rcs/RequestModal.vue';
import RequestModalGoogle from '@/components/rebranding/bm/RequestModalGoogle.vue';
import MCrop from '@/components/MCrop.vue';
import ChannelFeatures from '@/components/ChannelFeatures.vue';
import BucketService from '@/services/bucket.service';
import UploadComponent from '@/components/ui/UploadComponent/Index.vue';

const webApi = axios();

export default {
  name: 'webhooks',
  components: {
    PageHeader,
    ActiveTelegramModal,
    ActiveWhatsModal,
    RequestModalGoogle,
    RequestModal,
    MCrop,
    ChannelFeatures,
    UploadComponent,
  },
  data() {
    let whatsId = process.env.VUE_APP_WHATS_ID;
    const businessAccountsTemporary = [
      // '5cc32c49-7d10-4c2c-8aac-9dce25520d73',
      '142a07bd-25d6-4681-989d-d0cb0b4728ce',
      'cb83acd8-9746-4aeb-bda5-5d6bdffae4c3',
      '1c7c571c-5dfc-466c-925b-1c9cf3f93b41',
      'b0f68367-11b4-4796-8b37-5de7388fc6d7',
      '87449f80-72bc-4a81-96b3-aa2e3ed6f6df',
      'fbf761a7-92b7-4aeb-bb34-ec26d1da8971',
      'cdf3996d-b141-44ab-849a-9121d3b2ddce',
      '4a212df8-d961-45e7-b484-60a311a57fe2', // Label Tech
      'ea96a910-de46-4a3c-ae82-12344df70551', // High Link
    ];

    if (
      businessAccountsTemporary.indexOf(this.$store.state.account.id) !== -1
    ) {
      whatsId = '7f000001-840f-1f77-8184-15a9aa6229ea';
    } else {
      whatsId = process.env.VUE_APP_WHATS_ID;
    }

    return {
      formWhatsApp: {
        step: 'create_client',
      },
      callGoogleSend: false,
      formGoogle: {},
      cropPreview: null,
      files: [{}, {}],
      isSending: false,
      callRcsSend: false,
      agents: [],
      form: {},
      formRcs: {},
      agentEnabled: null,
      fetchedAll: false,
      swiper: null,
      fetched: false,
      loadPage: false,
      groupPages: [],
      instaIndex: 0,
      telegramIndex: 0,
      faceIndex: 0,
      teleIndex: 0,
      whatsIndex: 0,
      instaGroup: [],
      faceGroup: [],
      whatsGroup: [],
      teleGroup: [],
      rcsClient: {},
      mailClient: {},
      whatsClient: {},
      messengerChannel: {},
      instagramChannel: {},
      telegramChannel: {},
      whatsChannel: {},
      pages: [],
      bots: [],
      pagesInstagram: [],
      pagesGroupInstagram: [],
      whatsNumbers: [],
      pagesAgents: 1,
      q: '',
      menus: {
        WhatsApp: false,
        Messenger: false,
        Instagram: false,
        RCS: false,
        Widget: false,
        SMS: false,
        ReclameAqui: false,
        Telegram: false,
      },
      currentIndex: 0,
      currentChannel: {
        pages: {},
      },
      activingWhats: false,
      channels: {
        widget: {
          icon: 'fas fa-globe',
          name: 'Yuper Chat',
          anchor: 'menuWidget',
          iconHeader: 'webchat',
          active: true,
          available: true,
          channel_id: 0,
          description:
            'Automatize o suporte e vendas do seu site com Webchat.',
        },
        whatsapp: {
          icon: 'fab fa-whatsapp',
          name: 'WhatsApp',
          anchor: 'menuWhatsApp',
          iconHeader: 'whatsapp',
          active: false,
          available: true,
          channel_id: 0,
          description:
            'Envolva seus clientes via WhatsApp.',
        },
        googleBm: {
          icon: 'fab fa-google',
          name: 'RCS Google',
          anchor: 'menuRCS',
          iconHeader: 'google',
          active: false,
          available: true,
          channel_id: 0,
          description:
            'Conecte-se usando o Google Business Messages',
          info: {
            message: 'Seu agente do RBM inicia conversas com os usuários. Os usuários não podem iniciar conversas com seu agente, mas depois que ele inicia uma conversa, o usuário pode responder a qualquer momento.',
            features: [
              {
                text: 'Texto',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Imagem',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Arquivos',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Localização',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Eventos',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Carrossel',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Rich Cards',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Botões',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Respostas Rápidas',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Contatos',
                campaingn: true,
                chat: true,
              },
            ],
          },
        },
        google: {
          icon: 'fab fa-google',
          name: 'RCS Google',
          anchor: 'menuRCS',
          iconHeader: 'google',
          active: false,
          available: true,
          channel_id: 0,
          description:
            'Alcance seus clientes por meio do RCS Google.',
          info: {
            message: 'Seu agente do RBM inicia conversas com os usuários. Os usuários não podem iniciar conversas com seu agente, mas depois que ele inicia uma conversa, o usuário pode responder a qualquer momento.',
            features: [
              {
                text: 'Texto',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Imagem',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Arquivos',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Localização',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Eventos',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Carrossel',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Rich Cards',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Botões',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Respostas Rápidas',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Contatos',
                campaingn: true,
                chat: true,
              },
            ],
          },
        },
        sms: {
          icon: 'fas fa-sms',
          name: 'SMS',
          anchor: 'menuSMS',
          active: true,
          available: true,
          iconHeader: 'sms',
          channel_id: 0,
          description:
            'Alcance seu público em grande escala.',
        },
        messenger: {
          icon: 'fab fa-facebook-messenger',
          name: 'Messenger',
          anchor: 'menuMessenger',
          active: false,
          available: true,
          iconHeader: 'facebook',
          channel_id: 0,
          description:
            'Construa conversas ricas e engajadoras no Messenger.',
        },
        email: {
          icon: 'fas fa-envelope-open',
          name: 'Email',
          anchor: 'menuMail',
          active: true,
          available: true,
          iconHeader: 'mail',
          channel_id: 0,
          description:
            'Crie facilmente campanhas de e-mail marketing.',
        },
        instagram: {
          icon: 'fab fa-instagram',
          name: 'Instagram',
          anchor: 'menuInstagram',
          active: false,
          available: true,
          iconHeader: 'instagram',
          channel_id: 0,
          description:
            'Inclua anúncios do Instagram em seu mix de marketing.',
        },
        telegram: {
          icon: 'fab fa-telegram',
          name: 'Telegram',
          anchor: 'menuTelegram',
          active: false,
          available: true,
          iconHeader: 'telegram',
          channel_id: 0,
          description:
            'Forneça respostas instantâneas no Telegram.',
          info: {
            message: 'Seu agente do RBM inicia conversas com os usuários. Os usuários não podem iniciar conversas com seu agente, mas depois que ele inicia uma conversa, o usuário pode responder a qualquer momento.',
            features: [
              {
                text: 'Texto',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Imagem',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Arquivos',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Localização',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Eventos',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Carrossel',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Rich Cards',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Botões',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Respostas Rápidas',
                campaingn: true,
                chat: true,
              },
              {
                text: 'Contatos',
                campaingn: true,
                chat: true,
              },
            ],
          },
        },
      },
      messengerId: process.env.VUE_APP_MESSENGER_ID,
      instagramId: process.env.VUE_APP_INSTAGRAM_ID,
      whatsId,
      telegramId: process.env.VUE_APP_TELEGRAM_ID,
      telegramIdLive: process.env.VUE_APP_TELEGRAM_LIVE_ID,
      rcsRoute: 'https://yupchat.typeform.com/to/b0aG4Maq',
      rcsChannel: null,
      uploading: '',
    };
  },
  computed: {
    messengerRouter() {
      return process.env.VUE_APP_FACEBOOK_ROUTE;
    },
    newWhatsRoute() {
      const route = 'https://hub.360dialog.com/dashboard/app/YZKzG7PA/permissions?redirect_url=http://localhost:8081/channels';
      return route;
    },
    whatsRouter() {
      let redirectUri = 'https://dev.yup.chat/whatsapp/webhook-business';

      if (window.location.host.indexOf('app.') === 0) {
        redirectUri = 'https://app.yup.chat/whatsapp/webhook-business';
      }

      const url = `https://www.facebook.com/dialog/oauth?response_type=code&display=popup&app_id=571156307528909&redirect_uri=${encodeURIComponent(
        redirectUri,
      )}&scope=business_management,whatsapp_business_management&state=${encodeURIComponent(
        this.$store.state.account.id,
      )}&display=dialog`;
      console.log('URL');
      console.log(url);
      return url;
    },
    instagramRouter() {
      return process.env.VUE_APP_INSTAGRAM_ROUTE;
    },
  },
  created() {
    this.fetchRCS();
    this.fetchWhats();
    this.checkMessenger();
    this.checkDomains();

    this.$root.$on('sentTelegram', this.checkMessenger);
    setTimeout(() => {
      this.fetchedAll = true;
    }, 3000);
    this.checkWhatsEnable();
  },
  methods: {
    callFbLogin() {
      window.FB.login((response) => {
        if (response.authResponse) {
          const accessToken = response.authResponse.accessToken;
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      }, {
        config_id: '437774045697507',
        response_type: 'code',
        override_default_response_type: true,
        extras: {
          setup: {
          },
          feature: 'whatsapp_embedded_signup',
          sessionInfoVersion: 2,
        },
      });
      window.addEventListener('message', this.sessionInfoListener);
    },
    sessionInfoListener(event) {
      console.log('Facebook Event', event);
      if (event.origin !== 'https://www.facebook.com' && event.origin !== 'https://web.facebook.com') {
        return;
      }

      try {
        const data = JSON.parse(event.data);
        console.log('Data', event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          if (data.event === 'FINISH') {
            this.formWhatsApp.step = 'register_number';
            this.formWhatsApp.waba_business_id = data.data.phone_number_id;
            this.formWhatsApp.waba_external_id = data.data.waba_id;
            webApi.post('/channels/whatsapp/active', this.formWhatsApp)
              .then(
                (response) => {
                  this.isSending = false;
                  console.log(response);
                },
                (error) => {
                  console.log(error.response.data.message);
                  this.isSending = false;
                  this.$toast.show({
                    title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
                      error.code
                    }`,
                    content: error.response.data.message,
                    type: 'danger',
                  });
                },
              );
          } else {
            this.isSending = false;
          }
        }
      } catch {
        console.log('Non JSON Response', event.data);
        this.isSending = false;
      }
    },
    saveWhatsApp() {
      this.isSending = true;
      webApi.post('/channels/whatsapp/active', this.formWhatsApp)
        .then(
          (response) => {
            console.log('Response Whats', response);
            this.formWhatsApp.client_id = response.data.id;
            this.callFbLogin();
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
                error.code
              }`,
              content: error.response.data.message,
              type: 'danger',
            });
          },
        );
    },
    callUpload(uploading) {
      this.uploading = uploading;
      this.$refs.uploadMenu.toggle();
    },
    uploaded(response) {
      document.getElementById(this.uploading).style = `background: url(${response.data.path}); background-size: cover;`;
      console.log('Salvo', response);
      switch (this.uploading) {
        case 'cover-banner':
          this.formRcs.banner_url = response.data.path;
          break;
        case 'cover-logo':
          this.formRcs.logo_url = response.data.path;
          break;
        default:
          break;
      }
      this.$refs.uploadMenu.toggle();
    },
    saveRcs() {
      if (this.formRcs.logo_url === null) {
        this.$toast.show({
          title: 'Aviso',
          content: 'Você precisa fazer upload da logo',
          type: 'danger',
        });

        return;
      }

      if (this.formRcs.banner_url === null) {
        this.$toast.show({
          title: 'Aviso',
          content: 'Você precisa fazer upload do banner',
          type: 'danger',
        });

        return;
      }

      this.isSending = true;
      this.createAgent();
    },
    createAgent() {
      this.isSending = true;
      webApi.post('/channels/rcs/active', this.formRcs)
        .then(
          (response) => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: 'Solicitação enviada',
              type: 'success',
            });
            this.$emit('agent.submit');
            this.isSending = false;
            Swal.fire('Sucesso!', 'Solicitação enviada com sucesso. Breve entraremos em contato.', 'success');
            this.callRcsSend = false;
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
                error.code
              }`,
              content: error.response.data.message,
              type: 'danger',
            });
          },
        );
    },
    crop(data) {
      if (data) {
        document.getElementById(this.cropPreview).style = `background: url(${data.url}); background-size: cover;`;
        this.files[data.name] = data;
      }

      console.log(this.files);
    },
    callCrop(cropPreview, maxWidthP, maxHeightP, index) {
      this.$root.$on('call.crop', this.crop);
      this.cropPreview = cropPreview;
      console.log(index);
      this.$store.dispatch('crop/config', {
        maxWidth: maxWidthP,
        maxHeight: maxHeightP,
        name: index,
        viewMode: 2,
        aspectRatio: 1,
        minSize: true,
      });

      this.$root.$emit('show.modal', 'm-crop');
      this.hide(false, false);
    },
    callRcsRequest() {
      this.$root.$emit('show.modal', 'request-modal');
    },
    callGoogleRequest() {
      this.$root.$emit('show.modal', 'request-modal-google');
    },
    getAgents(page = 1) {
      this.$store
        .dispatch('fetchRcsAgents', { page, q: this.q })
        .then((response) => {
          /* this.agents = response.data;
          this.pagesAgents = response.last_page;
          if (this.rcsChannel != null) {
            this.agents.forEach((agent) => {
              if (this.rcsChannel.external_id === agent.id) {
                agent.agentEnabled = agent;
              }
            });
          } */
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeItem(currentChannel, item) {
      switch (currentChannel.iconHeader) {
        case 'instagram':
          this.removeInstagramPage(item.identification);
          break;
        case 'telegram':
          this.removeBot(item.botId);
          break;
        case 'whatsapp':
          this.removeNumber(item.phone_identification);
          break;
        case 'facebook':
          this.removePage(currentChannel.channel_id, item.identification);
          break;
        default:
          break;
      }
    },
    closeDialog() {
      // document.getElementById('close-dialog').addEventListener('click', closeBubble);
      document.querySelectorAll('.container-add').forEach((el) => el.remove());
    },
    anableTelegram() {
      if (this.form.token) {
        this.isSending = true;

        ChannelService.createTelegramBot({
          ...this.form,
          channel_id: this.telegramId,
          channel_live_id: this.telegramIdLive,
          end_point_send: 'http://localhost:8097/send/telegram',
        }).then(
          () => {
            this.$root.$emit('sentTelegram');
            this.hide();
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: 'Bot adicionado',
              type: 'success',
            });
            this.isSending = false;
          },
          (error) => {
            this.isSending = false;
            if (error.response.data.message.includes('409 Conflict')) {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
                  error.code
                }`,
                content: 'Bot já está cadastrado',
                type: 'danger',
              });
            } else {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
                  error.code
                }`,
                content: error.message,
                type: 'danger',
              });
            }
          },
        );
      } else {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: 'As informações precisam ser preenchidas',
          type: 'danger',
        });
      }
    },
    getGroup(itens, type = null) {
      const group = [];
      const sizeGroup = parseFloat(itens.length / 2).toFixed(1);

      for (let i = 0; i < sizeGroup; i += 1) {
        group.push([]);
      }
      group.forEach((item) => {
        let count = 0;
        itens.forEach((element) => {
          if (item.length < 3) {
            if (!item.includes(element)) {
              item.push(element);
              itens.splice(count, 1);
            }
          }
          count += 1;
        });
      });
      return group;
    },
    next() {
      this.currentIndex += 1;
    },
    prev() {
      this.currentIndex -= 1;
    },
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log('slide change');
    },
    addTelegramBot() {
      this.$root.$emit('show.modal', 'active-telegram-modal');
    },
    removePage(channel, id) {
      Swal.fire({
        title: 'Remover página',
        text: 'Tem certeza que gostaria de remover essa página?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.loadPage = true;
          ChannelService.channelsRemovePage(channel, id).then(
            () => {
              this.$modal.hide('dialog'); // Fecha o dialog após a execução do handler.
              this.loadPage = false;
              this.checkMessenger();
              this.$toast.show({
                title: 'Sucesso',
                content: 'Página removida',
                type: 'success',
              });
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
    goInstaRoute() {
      window.location.href = this.instagramRouter;
    },
    goRCS() {
      window.location.href = this.rcsRoute;
    },
    goFacebookRoute() {
      window.location.href = this.messengerRouter;
    },
    metMessage() {
      /* Swal.fire({
        title: 'Você tem uma conta business?',
        text: 'Tem uma conta business no facebook?',
        icon: 'warning',
        buttons: true,
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim',
        denyButtonText: 'Não',
      }).then((result) => {
        if (result.dismiss === 'cancel') {
          window.location.href = this.whatsRouter;
        }
        if (result.value) {
          this.$root.$emit('show.modal', 'active-whats-modal');
        }
      }); */
      window.location.href = this.newWhatsRoute;
    },
    removeBot(id) {
      Swal.fire({
        title: 'Remover bot',
        text: 'Tem certeza que gostaria de remover esse bot?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.loadPage = true;
          ChannelService.removeBot(
            id,
            process.env.VUE_APP_TELEGRAM_ID,
            this.telegramIdLive,
          ).then(
            () => {
              this.$modal.hide('dialog'); // Fecha o dialog após a execução do handler.
              this.loadPage = false;
              this.checkMessenger();
              this.$toast.show({
                title: 'Sucesso',
                content: 'Bot removido',
                type: 'success',
              });
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
    removeInstagramPage(id) {
      Swal.fire({
        title: 'Remover página',
        text: 'Tem certeza que gostaria de remover essa página?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.loadPage = true;
          ChannelService.channelsRemoveInstagram(id).then(
            () => {
              this.$modal.hide('dialog'); // Fecha o dialog após a execução do handler.
              this.loadPage = false;
              this.checkMessenger();
              this.$toast.show({
                title: 'Sucesso',
                content: 'Página removida',
                type: 'success',
              });
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
    enableRCS(agent) {
      if (agent.agentEnabled) {
        webApi
          .post('/channels/remove', {
            channel_id: process.env.VUE_APP_RCS_ID,
            external_id: agent.id,
            channel_live_id: process.env.VUE_APP_RCS_LIVE_ID,
          })
          .then(
            (response) => {
              Swal.fire('Sucesso!', 'RCS desativado com sucesso.', 'success');
              this.channels.google.active = false;
              this.fetchRCS();
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
                  error.code
                }`,
                content: error.response.data.message,
                type: 'danger',
              });
            },
          );
      } else {
        webApi
          .post('/channels/rcs', {
            channel_id: process.env.VUE_APP_RCS_ID,
            agent_id: agent.id,
            end_point_send: process.env.VUE_APP_RCS_END_POINT,
            agent_name: agent.name,
            channel_live_id: process.env.VUE_APP_RCS_LIVE_ID,
          })
          .then(
            (response) => {
              Swal.fire('Sucesso!', 'RCS ativado com sucesso.', 'success');
              this.fetchRCS();
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
                  error.code
                }`,
                content: error.response.data.message,
                type: 'danger',
              });
            },
          );
      }
    },
    removeNumber(number) {
      Swal.fire({
        title: 'Remover número',
        text: 'Tem certeza que gostaria de remover esse número?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.loadPage = true;
          webApi.delete(`whatsapp/new/numbers/${number}`).then(
            (response) => {
              this.$modal.hide('dialog'); // Fecha o dialog após a execução do handler.
              this.loadPage = false;
              this.checkMessenger();
              this.$toast.show({
                title: 'Sucesso',
                content: 'Número removido',
                type: 'success',
              });
            },
            (error) => {
              this.content = error;
              this.flowLoading = false;
            },
          );
        }
      });
    },
    checkDomains(page) {
      MailService.getDomains(page).then(
        (response) => {
          if (response.length > 0) {
            this.mailClient.active = true;
            this.channels.email.active = true;
          }
        },
        (error) => {
          this.content = error;
          console.log(JSON.stringify(error.response.data));
        },
      );
    },
    checkWhatsEnable() {
      if (this.$route.query.client != null && this.$route.query.channels != null) {
        webApi.post('/channels/whatsapp/active', {
          channel_id: this.$route.query.channels.replace('[', '').replace(']', ''),
          client_id: this.$route.query.client,
        })
          .then(
            (response) => {
              Swal.fire('Sucesso!', 'WhatsApp ativado com sucesso.', 'success');
              this.fetchRCS();
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
                  error.code
                }`,
                content: error.response.data.message,
                type: 'danger',
              });
            },
          );
      }
    },
    checkMessenger() {
      this.pagesInstagram = [];
      this.whatsNumbers = [];
      this.instaGroup = [];
      this.pages = [];
      this.getAccountChannel(this.whatsId);
      if (
        (this.$route.query.error === undefined ||
          this.$route.query.error === null) &&
        this.$route.query.channel != null
      ) {
        this.loadPage = true;
        switch (this.$route.query.channel) {
          case 'instagram':
            ChannelService.webhookMessenger({
              channel_id: this.instagramId,
              channel_live_id: process.env.VUE_APP_INSTAGRAM_LIVE_ID,
              account_identification: this.$route.query.code,
              external_id: this.$route.query.code,
              end_point_send: 'http://localhost:8097/send/instagram',
            })
              .then(
                (send) => {
                  this.loadPage = false;
                  Swal.fire('Ativado', 'Canal ativado com sucesso', 'success');
                },
                (error) => {
                  this.$toast.show({
                    title: 'Erro',
                    content: error.response.data.message,
                    type: 'danger',
                  });
                  this.isSending = false;
                },
              )
              .catch((error) => {
                console.log(error.response);
                this.isSending = false;
              });
            break;
          case 'messenger':
            ChannelService.webhookMessenger({
              channel_id: this.messengerId,
              channel_live_id: process.env.VUE_APP_MESSENGER_LIVE_ID,
              account_identification: this.$route.query.code,
              external_id: this.$route.query.code,
              end_point_send: 'http://localhost:8097/send/messenger',
            })
              .then(
                (send) => {
                  this.loadPage = false;
                  Swal.fire('Ativado', 'Canal ativado com sucesso', 'success');
                },
                (error) => {
                  this.$toast.show({
                    title: 'Erro',
                    content: error.response.data.message,
                    type: 'danger',
                  });
                  this.isSending = false;
                },
              )
              .catch((error) => {
                console.log(error.response);
                this.isSending = false;
              });
            break;
          case 'whatsapp-java':
            ChannelService.webhookMessenger({
              channel_id: this.whatsId,
              channel_live_id: process.env.VUE_APP_WHATS_LIVE_ID,
              account_identification: this.$route.query.code,
              external_id: this.$route.query.code,
              end_point_send: 'http://localhost:8097/send/messenger',
            })
              .then(
                (send) => {
                  this.loadPage = false;
                  Swal.fire('Ativado', 'Canal ativado com sucesso', 'success');
                },
                (error) => {
                  this.$toast.show({
                    title: 'Erro',
                    content: error.response.data.message,
                    type: 'danger',
                  });
                  this.isSending = false;
                },
              )
              .catch((error) => {
                console.log(error.response);
                this.isSending = false;
              });
            break;
          default:
            break;
        }
      }

      ChannelService.channels()
        .then(
          (response) => {
            response.data.forEach((data) => {
              if (data.channel_id === this.messengerId) {
                this.messengerChannel = data;
                if (data.deleted === 'false') {
                  this.channels.messenger.active = true;
                  this.channels.messenger.channel_id = data.id;
                }
              }
              if (data.channel_id === this.instagramId) {
                this.instagramChannel = data;
                if (data.deleted === 'false') {
                  this.channels.instagram.active = true;
                  this.channels.instagram.channel_id = data.id;
                }
              }
              if (data.channel_id === this.telegramId) {
                this.telegramChannel = data;
                if (data.deleted === 'false') {
                  this.channels.telegram.active = true;
                  this.channels.telegram.channel_id = data.id;
                }
              }
              if (data.channel_id === this.whatsId) {
                this.whatsChannel = data;
                if (data.deleted === 'false') {
                  this.channels.whatsapp.active = true;
                  this.channels.whatsapp.channel_id = data.id;
                }
              }
            });
          },
          (error) => {
            this.$toast.show({
              title: 'Erro',
              content: error.response.data.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        )
        .catch((error) => {
          console.log(error.response);
          this.isSending = false;
        });
      ChannelService.channelsPage(process.env.VUE_APP_MESSENGER_LIVE_ID)
        .then(
          (response) => {
            this.pages = [];
            const newPages = response.data;
            // let countIndex = 0;
            // let countIndexGroup = 0;
            newPages.forEach((page, index) => {
              if (page) {
                if (page.length > 0) {
                  this.pages.push(page[0]);
                }
              }
              // countIndex += 1;
            });
            this.channels.messenger.pages = this.pages;
          },
          (error) => {
            this.$toast.show({
              title: 'Erro',
              content: error.response.data.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        )
        .catch((error) => {
          console.log(error.response);
          this.isSending = false;
        });

      ChannelService.getTelegramBot()
        .then(
          (response) => {
            this.bots = [];
            response.data.forEach((item) => {
              if (item.deleted === false) {
                this.bots.push(item);
              }
            });
            if (this.bots.length === 0) {
              this.channels.telegram.active = false;
            }
            this.teleGroup = this.getGroup(this.bots);
          },
          (error) => {
            this.$toast.show({
              title: 'Erro',
              content: error.response.data.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        )
        .catch((error) => {
          console.log(error.response);
          this.isSending = false;
        });
      ChannelService.instagramPage(process.env.VUE_APP_INSTAGRAM_LIVE_ID)
        .then(
          (response) => {
            this.pagesInstagram = response.data;
            if (this.pagesInstagram.length === 0) {
              this.channels.instagram.active = false;
            }
            this.channels.instagram.pages = this.pagesInstagram;
          },
          (error) => {
            this.$toast.show({
              title: 'Erro',
              content: error.response.data.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        )
        .catch((error) => {
          console.log(error.response);
          this.isSending = false;
        });
    },
    getAccountChannel(channel) {
      this.whatsNumbers = [];
      webApi.get('whatsapp/new/numbers').then(
        (response) => {
          response.data.forEach((data) => {
            this.whatsNumbers.push(data);
          });
          this.channels.whatsapp.pages = this.whatsNumbers;
        },
        (error) => {
          this.content = error;
          this.flowLoading = false;
        },
      );
    },
    fetchWhats() {
      Promise.all([WhatsAppService.getClient()])
        .then(
          (responses) => {
            this.whatsClient = responses[0].data;
            // this.channels[1].active = this.whatsClient.status === 'active';
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    fetchRCS() {
      this.rcsChannel = null;
      webApi.get('/channels-all').then(
        (response) => {
          response.data.forEach((channel) => {
            if (
              channel.channel_id === process.env.VUE_APP_RCS_ID &&
              channel.deleted === 'false'
            ) {
              this.rcsChannel = channel;
              console.log('Canal RCS', channel);
              this.channels.google.active = true;
            }
          });
          this.getAgents();
        },
        (error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
              error.code
            }`,
            content: error.response.data.message,
            type: 'danger',
          });
        },
      );
    },
    fetch(page) {
      this.fetched = false;
    },
    closeOthers(exceptThis) {
      const menus = this.menus;

      exceptThis = exceptThis.substring(4);

      Object.keys(menus).forEach((key) => {
        console.log('closeOthers', key);

        if (exceptThis !== key) menus[key] = false;
      });
    },
    saveGoogle() {
      if (this.files[1].file === undefined) {
        this.$toast.show({
          title: 'Aviso',
          content: 'Você precisa fazer uploado da logo',
          type: 'danger',
        });

        return;
      }

      this.isSending = true;

      const filesCrop = [];
      filesCrop.push({
        x: this.files[0].x,
        y: this.files[0].y,
        w: this.files[0].width,
        h: this.files[0].height,
      });
      filesCrop.push({
        x: this.files[1].x,
        y: this.files[1].y,
        w: this.files[1].width,
        h: this.files[1].height,
      });
      const formDataCover = new FormData();
      formDataCover.append('files[]', this.files[0].file);
      formDataCover.append('files[]', this.files[1].file);
      formDataCover.append('role', 'public');
      formDataCover.append('folder', 'rcs');
      formDataCover.append('crop', JSON.stringify(filesCrop));

      Promise.all([
        BucketService.upload(formDataCover, true, this.callBackProgress),
      ])
        .then(
          (responses) => {
            this.isSending = false;
            console.log(responses);
            this.formGoogle.banner_url = responses[0].data[1].url;
            this.formGoogle.logo_url = responses[0].data[0].url;
            console.log(responses);
            this.createAgentGoogle();
          },
          (error) => {
            this.content = error;
            this.isSending = false;
            console.log(error);
            this.$toast.show({
              title: 'Erro interno',
              content: 'Erro ao tentar fazer upload.',
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.isSending = false;
        });
    },
    createAgentGoogle() {
      this.isSending = true;
      webApi.post('/channels/bm/active', this.formGoogle)
        .then(
          (response) => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: 'Solicitação enviada',
              type: 'success',
            });
            this.$emit('agent.submit');
            this.isSending = false;
            Swal.fire('Sucesso!', 'Solicitação enviada com sucesso. Breve entraremos em contato.', 'success');
            this.callGoogleSend = false;
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
                error.code
              }`,
              content: error.response.data.message,
              type: 'danger',
            });
          },
        );
    },
  },
};
</script>
<style scoped lang="scss">
.card-flow-header {
  display: flex;
  flex-direction: row;
}
.channel-item-card-status {
  width: 70%;
}
.flow-channel-item-menu {
  display: none;
  width: 30%;
}
.flow-channel-item-menu .dropdown {
  text-align: right;
}
.status-active {
  background: #5a99ec14;
  width: 90px;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  flex-direction: row;
  display: flex;
}
.status-active .status-indicate {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #5a99ec;
  margin-top: 7px;
  margin-right: 8px;
  margin-left: 15px;
}
.status-active .status-text {
  font-size: 12px;
  color: #5a99ec;
}
.status-inactive {
  background: #70707014;
  width: 90px;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  flex-direction: row;
  display: flex;
}
.status-inactive .status-indicate {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #707070;
  margin-top: 7px;
  margin-right: 8px;
  margin-left: 15px;
}
.status-inactive .status-text {
  font-size: 12px;
  color: #707070;
}
.m-10 {
  margin: 10px;
}
.right-menu::v-deep [id^='menu'] {
  overflow: hidden;
  position: fixed;
  z-index: 100;
  background: #fff !important;
  right: 0;
  top: 0;
  opacity: 1;
  width: 320px;
  height: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  box-shadow: 0 32px 25px 9px rgba(0, 0, 0, 0.12);
  transition: 0.2s all ease;
  display: block !important;

  &[aria-hidden='true'] {
    width: 0;
    opacity: 0;
    pointer-events: none;
  }

  & > aside {
    min-width: 320px;
    width: 100%;
  }
}
.card {
  position: relative;
  overflow: hidden;
  display: block;
  text-align: left;
  color: inherit;
  padding: 0;

  .card-header {
    font-weight: bold;

    .status {
      color: #fff;
      margin-top: 4px;
      padding: 2px 10px;
      border-radius: 16px;
      display: block;
      outline: 2px solid #fff;
      text-transform: uppercase;
      font-size: 7pt;
    }
  }

  .card-header,
  .card-body {
    position: relative;
    z-index: 10;
  }

  & > i {
    pointer-events: none;
    position: absolute;
    z-index: 9;
    right: -10px;
    bottom: -10px;
    font-size: 6.5em;
    color: #efefef;
  }
}
.darkmode .card > i {
  color: var(--background-3);
}
.menu-channels header {
  text-align: center;
  font-size: 23px;
  font-weight: bold;
  padding: 20px;
  color: #8a8a8a;
}
.menu-channels .icon-b {
  color: #f2f2f2;
  position: absolute;
  bottom: -8px;
  left: -8px;
  font-size: 100px;
  z-index: -1;
}
.channel-status {
  padding: 5px;
  color: #fff;
  font-size: 13px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  padding-bottom: 0px;
  height: 30px;
  margin-right: 15px;
  font-weight: normal;
}
.menu-channels p {
  padding: 15px;
}
.menu-channels-body button {
  margin-left: 15px;
}
.tile-success {
  background-color: #2fbfa0 !important;
  border-radius: 10px;
  box-shadow: 0 15px 20px #2fbfa046 !important;
}
.tile-primary {
  background-color: #635ebe !important;
  box-shadow: 0 15px 20px rgba(99, 94, 190, 0.4) !important;
  border-radius: 10px;
}
.tile-primary span {
  color: #fff !important;
}
.tile-primary i {
  color: #fff !important;
}
.tile-success i {
  color: #fff !important;
}
.tile-success span {
  color: #fff !important;
}
.right-menu::v-deep [id^='menu'] {
  overflow-y: scroll !important;
}
.limit-page {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 190px;
  display: block;
}
.color-white {
  color: #fff !important;
}
.img-icon-cards {
  width: 40px;
}
.header-card-itens-menu {
  width: auto;
  margin: 0 auto;
  margin-top: 10px;
}
.header-card-itens-menu img {
  float: left;
}
.header-card-itens-menu div {
  padding-left: 10px;
  padding-top: 7px;
  float: left;
}
.card-itens-footer-container {
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  widows: 100%;
  display: flex;
  z-index: 2;
}
.card-itens-footer-container a {
  color: #752de6;
  text-align: center;
  z-index: 2;
}
.c-pointer {
  cursor: pointer !important;
}
.card-itens-footer-container-button {
  background-color: #6eda2c;
  color: white;
  border: none;
  padding: 10px;
  width: 80%;
  margin: 0 auto;
  z-index: 1;
  border-radius: 5px;
  position: absolute;
  bottom: 40px;
  left: 30px;
}
.card-itens-footer-container-button:hover {
  background-color: #75e72e;
}
.card-itens-footer-container-button i {
  color: white;
}
.card .card-body {
  z-index: 1 !important;
}
.header-card-itens-menu-open {
  width: 100%;
  position: relative;
}
.header-card-itens-menu-open .img-icon-cards {
  width: 30px !important;
  float: left;
}
.header-card-itens-menu-open div {
  margin-left: 5px;
  margin-top: 2px;
  float: left;
}
.header-card-itens-menu-open-title {
  flex: 2;
}
.header-card-itens-menu-open-close {
  position: absolute;
  right: 0;
}
.btn-card-open-fill {
  background: #752de6;
  border: none;
  color: white;
  width: 80%;
  padding: 5px;
  border-radius: 5px;
  display: block;
  margin: 0 auto;
  margin-top: 60px;
}
.btn-card-open-fill:hover {
  background: #7e38ef;
}
.btn-card-open-fill i {
  color: white;
}
.card-itens-menu-body-open {
  text-align: center;
}
.card-itens-menu-body-open {
  .btn.btn-yup-purple-outline {
    width: 80%;
    margin-top: 20px;
  }
}
.btn-card-open-borderd {
  background: #fff;
  border: none;
  color: white;
  width: 80%;
  padding: 5px;
  border-radius: 8px;
  border: solid 1px #7e38ef;
  margin: 0 auto;
  margin-top: 20px;
  display: block;
}
.btn-card-open-borderd {
  color: #7e38ef;
}
.btn-card-open-borderd i {
  color: #7e38ef;
}
.btn-add-new-account {
  color: #7e38ef !important;
  padding: 10px;
  padding-left: 40px;
  width: 60%;
  margin-bottom: 30px;
}
.btn-add-new-account span {
  color: #7e38ef;
}
.btn-add-new-account {
  transition: all 0.2s;
}
.btn-add-new-account:hover {
  background: #7e38ef;
}
.btn-add:hover {
  background: #7e38ef;
}
.btn-add-new-account:hover span {
  color: #fff;
}
.btn-add-new-account:hover {
  color: #fff !important;
}
.btn-add-new-account:hover {
  padding-left: 43px;
}
.btn-card-open-link {
  color: #7e38ef !important;
  margin-top: 20px;
  display: block;
}
.btn-card-open-add-new-item {
  background: #fff;
  border: 1px dashed #752de6;
  color: #752de6;
  padding: 40px;
  margin: 0 auto;
  margin-top: 20px;
  font-size: 15px;
}
.btn-card-open-add-new-item i {
  color: #752de6;
  display: block;
}
.card-itens-menu-footer-open {
  background: #f7f7f7;
  width: 100%;
  height: 160px;
  position: absolute;
  bottom: 0;
  padding: 20px;
}
.darkmode .card-itens-menu-footer-open,
.darkmode .btn-card-open-add-new-item {
  background-color: var(--background-3);
}
.swiper {
  width: 300px;
  height: 100px;
}
.card-itens-menu-footer-open-buttons-title {
  float: left;
  width: 90%;
}
.card-itens-menu-footer-open-buttons-remove {
  float: left;
  width: 10%;
}
.card-itens-menu-footer-open-buttons-remove img {
  width: 13px;
}
.card-itens-menu-paginate {
  text-align: center;
  display: flex;
  align-items: center;
  height: 40px;
  flex-direction: row;
}
.card-itens-menu-paginate-button {
  background: #9a6de1;
  width: 10px;
  height: 10px;
  border-radius: 30px;
  margin-right: 8px;
  cursor: pointer;
}
.card-itens-menu-paginate-button.active {
  background: #752de6;
  width: 10px;
  height: 10px;
  border-radius: 30px;
  margin-right: 8px;
  cursor: pointer;
}
.card-itens-menu-paginate-button:hover {
  background: #8242e9;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.9s ease;
  overflow: hidden;
  visibility: visible;
  position: absolute;
  width: 100%;
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  visibility: hidden;
  width: 100%;
  opacity: 0;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 40%;
  width: auto;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.7s ease;
  border-radius: 0 4px 4px 0;
  text-decoration: none;
  user-select: none;
}

.next {
  right: 0;
}

.prev {
  left: 0;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.9);
}
.span-type-flow {
  font-size: 13px;
  background: #e8e8e8;
  padding: 5px;
  padding-right: 5px;
  padding-left: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
}
.search-btn {
  position: absolute;
  top: 5px;
  right: 12%;
  cursor: pointer;
}
.form-search {
  width: 80% !important;
  display: block;
  margin: 0 auto;
}
.create_flow_btn {
  color: var(--clr-yup-purple) !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}
.card-flow-template {
  background: #fff;
  border: solid 1px #dfdfdf;
  border-radius: 10px;
  margin-bottom: 10px;
}
/* Rebranding */
.card.card-tabs .card-header .nav.nav-tabs {
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}
.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}
.nav.nav-tabs .nav-item .nav-link.router-link-exact-active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}
.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}

.custom-control-label {
  &::after,
  &::before {
    top: -0.5rem;
  }
}
.btn i {
  line-height: 1;
  color: inherit;
}
.btn {
  margin-bottom: 5px;
}
.btn-search {
  margin-left: 8px;
}
.type-flow-item {
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 5px;
  display: block;
}
.type-flow-item:hover {
  background: #752de6;
  color: #fff;
}
.type-flow-item:hover p {
  color: #fff;
}
.type-flow-item:hover i {
  color: #fff;
}
.type-flow-item-active {
  background: #752de6;
  color: #fff;
}
.type-flow-item-active p {
  color: #fff;
}
.type-flow-item-active i {
  color: #fff;
}
.flow-trigger-icon {
  font-size: 25px;
  cursor: pointer;
}
.color-white {
  color: #fff !important;
}
.m-left-5 {
  margin-left: 5px !important;
}
.flow-channel-item {
  border: solid 1px #ecedee;
  box-shadow: 0px 0px 12px #752de629;
  width: 57px;
  height: 57px;
  border-radius: 4px;
  padding: 10px;
  float: left;
}
.flow-channel-item-line {
  width: 80px;
  height: 0px;
  border: 1px solid #9ca7b0;
  float: left;
  margin-top: 25px;
  margin-left: 5px;
  margin-right: 5px;
}
.element-flow-item-card {
  margin: 0 auto;
  margin-bottom: 60px;
  margin-top: 60px;
}
.element-flow-item-card p {
  font-size: 13px;
  color: #9b9b9b;
}
.element-flow-item-title {
  color: #0e1421;
  font-weight: 600;
}
.refresh-date-item {
  color: #9ca7b0;
  font-size: 10px;
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.flow-channel-item-check {
  float: left;
}

.flow-channel-item-menu {
  cursor: pointer !important;
}
.btn-new-flow {
  background: #752de6 !important;
  border: solid 1px #752de6 !important;
}
.btn-new-flow:hover {
  background: #8238f9 !important;
}
.tab-flow ul li.active {
  color: #8238f9 !important;
}
.tab-flow {
  padding: 30px 30px 0px 0px;
  border-bottom: solid 1px #0000001a;
  width: 100%;
  margin-bottom: 20px;
}
.tab-flow ul {
  list-style: none;
  margin: 0px;
  padding-left: 15px;
  position: relative;
}
.tab-flow ul li {
  color: #423c4c;
  padding-bottom: 50px;
  float: left;
}
.tab-flow ul li {
  width: 180px;
  padding-left: 30px;
  cursor: pointer;
}
.tab-flow ul .tab-indicator {
  width: 180px;
  content: '';
  border-bottom: 2px solid #752de6;
  display: inline-block;
  position: absolute;
  bottom: -80px;
  left: 10px;
  transition: left 0.5s;
}
.tab-flow ul li span {
  color: #423c4c;
  float: left;
  font-size: 28px !important;
}
.tab-flow ul li strong {
  color: #423c4c;
  float: left;
  font-size: 18px;
  font-weight: 200;
}

.tab-flow ul li:hover strong {
  color: #8238f9 !important;
}
.tab-flow ul li:hover span {
  color: #8238f9 !important;
}
.tab-flow ul li.active strong {
  color: #8238f9 !important;
}
.tab-flow ul li.active span {
  color: #8238f9 !important;
}
.title-new-flow {
  font-weight: 100;
}
.flow-channel-item-modal {
  border: solid 1px #ecedee;
  box-shadow: 0px 0px 12px #752de629;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
}
.flow-channel-item-modal img {
  width: 30% !important;
}
.top2 {
  margin-top: 2px !important;
}
.left15 {
  margin-left: 15px !important;
}
@media (max-width: 666px) {
  .tab-indicator {
    display: none !important;
  }
}
.no-item-flow span {
  font-size: 70px !important;
  color: #ccc;
}
.no-item-flow h3 {
  color: #ccc;
}
.no-item-flow {
  margin-top: 20px;
  margin-bottom: 20px;
}

.all-check-box {
  .check-all-info {
    color: var(--foreground);
  }
}
.p20 {
  margin-bottom: 20px !important;
}
.social-temp {
  border: 3px solid #fff;
  border-radius: 50%;
}
.btn-add {
  display: flex;
  align-items: center;
  justify-content: center;
  width: min-content;
  background-color: #6b23dc;
  color: #fff;
  font-weight: 400;
  border-radius: 8px;
  font-size: 1rem;
}
::v-deep #sidebar-right-channel {
  background-color: var(--background-2) !important;
  width: 525px;
  small {
    color: var(--gray-font-color);
  }
  // Default sidebar header
  .b-sidebar-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }
  // Custom sidebar header
  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: 2.5rem;
    p {
      font-weight: 500;
      font-size: 1.25rem;
    }
    span {
      color: var(--gray-font-color-5);
    }
    span.sidebar-close {
      position: absolute;
      top: 12px;
      right: 0;
    }
    .arrow-back {
      position: absolute;
      top: 12px;
      left: -10px;
    }
  }
  .b-sidebar-body {
    padding: 0 1.5rem;
  }
  header {
    > strong {
      order: -1;
    }
  }
  .desc-column {
    p {
      color: var(--gray-font-color-4);
    }
  }
}
::v-deep #sidebar-right-rcs {
  background-color: var(--background-2) !important;
  width: 525px;
  small {
    color: var(--gray-font-color);
  }
  // Default sidebar header
  .b-sidebar-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }
  // Custom sidebar header
  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: 2.5rem;
    p {
      font-weight: 500;
      font-size: 1.25rem;
    }
    span {
      color: var(--gray-font-color-5);
    }
    span.sidebar-close {
      position: absolute;
      top: 12px;
      right: 0;
    }
    .arrow-back {
      position: absolute;
      top: 12px;
      left: -10px;
    }
  }
  .b-sidebar-body {
    padding: 0 1.5rem;
  }
  header {
    > strong {
      order: -1;
    }
  }
  .desc-column {
    p {
      color: var(--gray-font-color-4);
    }
  }
}
::v-deep #sidebar-right-google {
  background-color: var(--background-2) !important;
  width: 525px;
  small {
    color: var(--gray-font-color);
  }
  // Default sidebar header
  .b-sidebar-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }
  // Custom sidebar header
  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: 2.5rem;
    p {
      font-weight: 500;
      font-size: 1.25rem;
    }
    span {
      color: var(--gray-font-color-5);
    }
    span.sidebar-close {
      position: absolute;
      top: 12px;
      right: 0;
    }
    .arrow-back {
      position: absolute;
      top: 12px;
      left: -10px;
    }
  }
  .b-sidebar-body {
    padding: 0 1.5rem;
  }
  header {
    > strong {
      order: -1;
    }
  }
  .desc-column {
    p {
      color: var(--gray-font-color-4);
    }
  }
}
.info-header-sidebar {
  background: #f2f2f2;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 20px;
}
.info-header-sidebar h4 {
  margin-bottom: 0px;
  font-weight: 400;
}
.account-list-side-bar {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  padding-left: 15px;
  padding-right: 0px;
}
.account-list-side-bar-text {
  width: 90%;
  color: #707070;
}
.account-list-side-bar-icon span {
  color: #d5273e;
  transition: all 0.5s;
}
.account-list-side-bar-icon span:hover {
  color: #020101 !important;
}
.footer-sidebar-channel {
  margin-top: 90px;
  margin-left: 18px;
}
.btn-simulate {
  width: 70px !important;
  display: block;
}
.no-pages {
  color: #bbb;
  font-size: 34px;
}
.no-pages h4 {
  font-weight: 100;
  font-size: 20px;
  color: #bbb;
}
.add-prod-form {
  .input-group {
    margin-bottom: 2rem;
  }

  label {
    display: flex;
    gap: 5px;
    width: 100%;
    margin-bottom: 0.725rem;
    font-size: 1rem !important;

    span {
      color: var(--gray-font-color-3);
    }
  }
}
.container-add {
  max-width: 1740px;
}
.card-add-list {
  background-color: #37cbce;
  overflow: hidden;
  position: relative;
  height: 264px;
  border-radius: 8px;
  margin-bottom: 4rem;

  > .card-body {
    display: flex;
  }

  .card-text {
    color: #fff;
    font-size: 24px;
    position: relative;
    z-index: 1;
  }

  .img-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 437px;
    height: 437px;
    background-color: #a941fa;
    border-radius: 50%; */
    z-index: 0;
  }

  .btn-add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: min-content;
    background-color: transparent;
    color: #fff;
    font-weight: 400;
    font-size: 1rem;
    border: 1px solid;
  }
}
#close-dialog {
  position: absolute;
  top: 6px;
  right: 10px;
  cursor: pointer;
  transform: scale(1.25);
  padding: 10px;
  background: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
#close-dialog::after {
  content: '\e5cd';
  font-family: 'Material Symbols Outlined';
  color: #fff;
  transform: scale(1.6);
}
.refresh-date-item {
  background: #EEE6FE;
  padding: 4px 8px 4px 8px !important;
  border-radius: 8px;
  width: 90%;
}
.refresh-date-item .create_flow_btn {
  color: #6B23DC !important;
}
.m-bottom-10 {
  margin-bottom: 10px !important;
}
.btn-upload {
  display: flex;
  width: 40%;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 10px;
}
</style>
